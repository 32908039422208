<template>
	<div class="footer">
		<div class="footer_new">
			<p>Copyright © 2021. All rights reserved.</p>
		</div>		
	</div>
</template>

<script>
export default {
	data(){
		return {
			host:"https://www.interstellarsfx.com/",
			lang:localStorage.getItem('lang') || ''
		}
	},
	
	methods: {
		jump(url){
		location.href=url
		},
		
		// enter(v){
		//   if(v==0){
		//      if(localStorage.getItem('lang')=='zh'){
		//          location.href="https://www.interstellarfx.net/zh/mt4"
		//      }else if(localStorage.getItem('lang')=='vi'){
		//          location.href="https://www.interstellarfx.net/vi/mt4"
		//      }else{
		//          location.href="https://www.interstellarfx.net/mt4"  }		
		//   }
		// }
		
		enter(v){
		  if(v==0){
			  let lang = localStorage.getItem('lang') || ""
			  lang = lang=="en"?"":lang
			  location.href="https://www.interstellarfx.net/"+lang+"/forex-trade"
		   //   if(localStorage.getItem('lang')=='zh'){
		   //       location.href="https://www.interstellarfx.net/zh/forex-trade"
		   //   }else if(localStorage.getItem('lang')=='vi'){
		   //       location.href="https://www.interstellarfx.net/vi/forex-trade"
		   //   }else{
		   //       location.href="https://www.interstellarfx.net/forex-trade"  
				 // }		
		  }
		},
		

		
		

		
		
	},
	
	mounted() {
		
		 // localStorage.getItem('lang') || 'en'
		 let tmp_lang = localStorage.getItem('lang') || ""
		 //console.log("tmp_lang",tmp_lang)
		 tmp_lang = tmp_lang == "en" ? "" : tmp_lang
		 this.host = tmp_lang=="zh"?"https://www.interstellarfx.net/":
		 tmp_lang==""?"https://www.interstellarsfx.com":"https://www.interstellarsfx.com/"
		 this.lang = tmp_lang
	}
}
</script>

<style scoped>
/* .footer_main h3 a {text-decoration:none}; */

.footer_new{background: #212529; color: #FFFFFF; padding: 24px 0; text-align: center; font-size: 12px;}

.footer_box{ background: #2f3b49; color: #a9abad; padding: 50px 0;}
.footer_main{ text-align: left;}
.footer_main h2{ font-size: 14px; padding-bottom: 5px;}
.footer_main h3{ font-size: 12px; font-weight: normal; line-height: 28px; cursor: pointer;}
.footer_box_b{ background: #232c36; color: #ffffff; padding: 50px 0;  text-align: left; color: #a9abad;}
.footer_main_b h4{    
	font-size: 12px;
    padding-bottom: 10px;
    font-weight: normal;
    padding-top: 10px;
	text-align: justify;
	}
.footer_box_b_wenan{ font-weight: bold;}
.footer_main_b h5{font-size: 12px; padding-bottom: 5px; font-weight: normal;    text-align: justify;}
.footer_db{ padding: 10px 0;}
.footer_db span{font-size: 12px; padding-bottom: 5px; color: #e00007; display: inline; padding-right: 50px;}
.footer_main_b p{font-size: 12px; padding-bottom: 5px; font-weight: normal;}
.footer_box_o{ 
	width: 100%;
	height: 380px;
	background: url( '../assets/img/footer_banner.jpg');
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
}
.footer_main_o{ text-align: center;}
.footer_o_left{ text-align: left; color: #ffffff; }
.footer_o_left h2{font-size: 36px;}
.footer_o_left p{font-size: 36px;  margin-top: 20px;}
.button_red{ 
	    background: #e00007; 
		color: #ffffff;
		border-radius: 3px;
		padding: 10px 80px;
		font-weight: 600;
		margin: 20px 0;
		cursor: pointer;
		
		}
.button_red a{ color: #fff;}
.button_green{ 
	    background: #2d8319;
	    color: #ffffff;
	    border-radius: 3px;
	    padding: 10px 80px;
	    font-weight: 600;
	    margin: 20px 0;
		cursor: pointer;
}

.button_green a{ color: #fff;}
.footer_a_up_w{
	    color: #fff;
	    font-weight: bold;
	    padding-left: 15px;
	    line-height: 30px;
}
.footer_a_up_xt{ text-align: left; color: #fff; }

.footer_a_up_xt h2{ font-size: 16px; margin-top: 5px;}

.footer_a_down{ margin-top: 30px;}

.footer_a_down_w{
	color: #fff;
	font-weight: bold;
	padding-left: 15px;
	line-height: 15px;
}

.footer_main h3 a {text-decoration:none; color: #a9abad;}

a {text-decoration:none; }
	/* 当页面宽度大于750px小于1200px */
	 @media screen and (min-width:750px) and (max-width:1160px){
		.footer_a{ padding-bottom: 30px;}
		
		.footer_box{
		    background: #2f3b49;
		    color: #a9abad;
		    padding: 30px 20px;
		}
		
		.footer_main{
		    text-align: left;
		    display: block;
		}
		
		.footer_main h2 {
		    font-size: 20px;
			padding-top: 20px;
		}
		
		.footer_main h3 a {
		    text-decoration: none;
		    color: #a9abad;
		    font-size: 16px;
		}
		
		.footer_main_o{
		    text-align: center;
		    padding-top: 12%;
			display: block;
		}
		
		.footer_o_left{
		    text-align: center;
		    color: #ffffff;
		}
		
		.footer_main_b{ width: 90%;}
		
		.button_red{ padding: 10px 50px; cursor: pointer;}
		
		.button_green{ padding: 10px 50px; cursor: pointer;}
		
		
}

	/* 兼容手机 */
	@media screen and (max-width: 750px) {
		.footer_a{ padding-bottom: 30px;}
		
		.footer_box{
		    background: #2f3b49;
		    color: #a9abad;
		    padding: 30px 20px;
		}
		
		.footer_main{
		    text-align: left;
		    display: block;
		}
		
		.footer_main h2 {
		    font-size: 20px;
			padding-top: 20px;
		}
		
		.footer_main h3 a {
		    text-decoration: none;
		    color: #a9abad;
		    font-size: 16px;
		}
		
		.footer_main_o{
		    text-align: center;
		    padding-top: 12%;
			display: block;
		}
		
		.footer_o_left{
		    text-align: center;
		    color: #ffffff;
		}
		
		.footer_main_b{ width: 90%;}
		
		.button_red{ padding: 10px 50px; cursor: pointer;}
		
		.button_green{ padding: 10px 50px; cursor: pointer;}
		
		
}

</style>