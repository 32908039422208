<template>
  <div class="head">
    <!-- 		<div class="head_sj">
			<div class="head_tb">
				<img src="../assets/img/login_sanjiao.png">
			</div>			
		</div> -->
    <!-- <div id="leafContainer"></div> -->
    <div class="head_fix" id="head_fix_box">
      <div class="head_top flex a-center j-center" v-if="head_show">
        <div class="head_top_left">
          <p>{{ $t('myhead.myhead_r') }}</p>
        </div>

        <div class="head_top_right" @click="top_close_button">
          <i class="el-icon-close"></i>
        </div>
      </div>

      <div class="head_nav_assistant">
        <ul class="container flex a-center a-end j-end">
          <!-- <li class="flex a-center j-center">
            <div class="head_nav_assistant_icon">
              <img src="../assets/img/shopping_bag.png" />
            </div>
            <div class="head_nav_assistant_font">
              <a @click="pageTo(1)">{{ $t('Home.Home_kk') }}</a>
            </div>
          </li> -->

          <li class="flex a-center j-center" v-if="false">
            <div class="head_nav_assistant_icon">
              <img src="../assets/img/headset_mic.png" />
            </div>
            <div class="head_nav_assistant_font">
              <a :href="host + lang + '/contact-us'">
                {{ $t('footerbox.footerbox_dd') }}</a
              >
            </div>
          </li>

          <li class="flex a-center j-center">
            <div class="head_nav_assistant_icon">
              <img src="../assets/img/language.png" />
            </div>
            <div class="head_nav_assistant_font">
              <div class="langGroup" style="position: relative">
                <h4 class="flex a-center j-center" style="font-weight: normal">
                  <div class="nationalicon" :class="[lang]"></div>
                  {{ lang | langFilter }}
                  <i class="el-icon-caret-bottom"></i>
                </h4>
                <div class="national">
                  <div class="item flex a-center" @click="langFn('en')">
                    <div class="nationalicon en"></div>
                    English
                  </div>
                  <div class="item flex a-center" @click="langFn('ru')">
                    <div class="nationalicon ru"></div>
                    Русский
                  </div>
                  <div class="item flex a-center" @click="langFn('id')">
                    <div class="nationalicon id"></div>
                    Indonesia
                  </div>

                  <div class="item flex a-center" @click="langFn('ja')">
                    <div class="nationalicon ja"></div>
                    日本語
                  </div>

                  <div class="item flex a-center" @click="langFn('ko')">
                    <div class="nationalicon ko"></div>
                    한국어
                  </div>

                  <div class="item flex a-center" @click="langFn('ms')">
                    <div class="nationalicon ms"></div>
                    Melayu
                  </div>

                  <div class="item flex a-center" @click="langFn('th')">
                    <div class="nationalicon th"></div>
                    ไทย
                  </div>

                  <div class="item flex a-center" @click="langFn('vi')">
                    <div class="nationalicon vi"></div>
                    Tiếng Việt
                  </div>

                  <!-- <div class="item flex a-center" @click="langFn('zh')">
                    <div class="nationalicon zh"></div>
                    简体中文
                  </div>

                  <div class="item flex a-center" @click="langFn('zh-tw')">
                    <div class="nationalicon zh-tw"></div>
                    繁体中文
                  </div> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="header container flex a-center jlr j-center">
        <div class="logo logo_pc">
          <a href="/" style="text-decoration: none; color: inherit"
            ><h1>{{ $t('Home.Home_kk') }}</h1></a
          >
          <!-- <a :href="host + lang + '/home'"> <img src="../assets/logo.png" /></a> -->
          <!-- <a :href="host + lang + '/home'"> <img src="../assets/logo.png" /></a> -->
        </div>
        <div class="logo logo_sj">
          <div class="logo_sj_box flex jlr a-center j-center">
            <div class="logo_sj_box_left">
              <a :href="host + lang + '/home'"
                ><img src="../assets/logo_xiao.png"
              /></a>
              <!-- <a :href="host + lang + '/home'"><img src="../assets/logo_xiao.png"/></a> -->
            </div>

            <div
              class="login_button login_button_sj flex a-center j-center"
              style="display: none"
            >
              <button v-if="!anniu_login" @click="pageTo(6)">
                <svg
                  t="1690278123418"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="9664"
                  width="16"
                  height="16"
                >
                  <path
                    d="M535.6 511.8c95.1-10.5 172.1-87.5 182.6-182.6C732 204 634.4 98.2 512 98.2c-114.6 0-207.5 92.8-207.5 207.4v0.1c0 122.4 105.9 219.9 231.1 206.1zM512 572.4c-138.5 0-415 79.4-415 237.1v88.9c0 16.4 13.3 29.6 29.6 29.6h770.7c16.4 0 29.6-13.3 29.6-29.6v-88.9C927 651.9 650.5 572.4 512 572.4z"
                    p-id="9665"
                    fill="#ffffff"
                  ></path>
                </svg>
                {{ $t('myhead.loginName') }}
              </button>
              <button v-else @click="enter('/meber')">
                <svg
                  t="1690278123418"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="9664"
                  width="16"
                  height="16"
                >
                  <path
                    d="M535.6 511.8c95.1-10.5 172.1-87.5 182.6-182.6C732 204 634.4 98.2 512 98.2c-114.6 0-207.5 92.8-207.5 207.4v0.1c0 122.4 105.9 219.9 231.1 206.1zM512 572.4c-138.5 0-415 79.4-415 237.1v88.9c0 16.4 13.3 29.6 29.6 29.6h770.7c16.4 0 29.6-13.3 29.6-29.6v-88.9C927 651.9 650.5 572.4 512 572.4z"
                    p-id="9665"
                    fill="#ffffff"
                  ></path>
                </svg>
                {{ $t('myhead.Personalcenter') }}
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="logo"><img src="../assets/logo-square.svg"></div> -->
        <div class="nav_box">
          <ul class="nav flex" id="nav">
            <!-- <li style="background:none !important; color:#000 !important;"> -->
            <!-- <li style="background: none !important"> -->
            <!-- <h4 @click="pageTo(1)">{{$t('myhead.myhead_a')}} </h4>	 -->
            <!-- <h4 @click="pageTo(1)"></h4>
            </li> -->

            <li
              class="typeGroup-item"
              v-for="item in typeList"
              @click="tab(item)"
              :key="item.no"
            >
              <!-- <div class="icon"><img :src="item.icon" /></div> -->
              <h4>{{ item.name }}</h4>
            </li>
            <!-- 首页end -->

            <!-- <div class="login_button flex a-center j-center"  @click="pageTo(6)">登录</div> -->
            <div
              class="login_button flex a-center j-center"
              style="margin-left: 10px"
            >
              <button
                v-if="!anniu_login"
                @click="pageTo(6)"
                class="login_button flex a-center j-center"
              >
                <svg
                  t="1690278123418"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="9664"
                  width="16"
                  height="16"
                >
                  <path
                    d="M535.6 511.8c95.1-10.5 172.1-87.5 182.6-182.6C732 204 634.4 98.2 512 98.2c-114.6 0-207.5 92.8-207.5 207.4v0.1c0 122.4 105.9 219.9 231.1 206.1zM512 572.4c-138.5 0-415 79.4-415 237.1v88.9c0 16.4 13.3 29.6 29.6 29.6h770.7c16.4 0 29.6-13.3 29.6-29.6v-88.9C927 651.9 650.5 572.4 512 572.4z"
                    p-id="9665"
                    fill="#ffffff"
                  ></path>
                </svg>
                {{ $t('myhead.loginName') }}
              </button>
              <button
                v-else
                @click="enter('/meber')"
                class="login_button flex a-center j-center"
              >
                <svg
                  t="1690278123418"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="9664"
                  width="16"
                  height="16"
                >
                  <path
                    d="M535.6 511.8c95.1-10.5 172.1-87.5 182.6-182.6C732 204 634.4 98.2 512 98.2c-114.6 0-207.5 92.8-207.5 207.4v0.1c0 122.4 105.9 219.9 231.1 206.1zM512 572.4c-138.5 0-415 79.4-415 237.1v88.9c0 16.4 13.3 29.6 29.6 29.6h770.7c16.4 0 29.6-13.3 29.6-29.6v-88.9C927 651.9 650.5 572.4 512 572.4z"
                    p-id="9665"
                    fill="#ffffff"
                  ></path>
                </svg>
                {{ $t('myhead.Personalcenter') }}
              </button>
            </div>
          </ul>
        </div>
      </div>
      <div class="phoneMenu" @click="toogNav()">
        <!-- <i :class="phoneIcon"></i> -->
        <svg
          t="1690266589372"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6712"
          width="32"
          height="32"
        >
          <path
            d="M136.53504 260.48h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.02496s-25.97888-58.02496-58.01984-58.02496H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.02496s25.97888 58.02496 58.02496 58.02496zM887.47008 434.56H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.02496s25.97888 58.02496 58.02496 58.02496h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.02496s-25.97888-58.02496-58.01984-58.02496zM887.47008 714.44992H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.03008s25.97888 58.03008 58.02496 58.03008h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.03008s-25.97888-58.03008-58.01984-58.03008z"
            p-id="6713"
            fill="#ffffff"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/index.js'

import { changeLang } from '@/utils/util.js'
export default {
  data() {
    return {
      head_show: false,
      w: 0,
      host: 'https://www.interstellarsfx.com/', //固定的网址
      anniu_login: false,
      phoneIcon: 'el-icon-more',
      lang: localStorage.getItem('lang') || 'en',
      typeList: [],
    }
  },
  filters: {
    langFilter(name) {
      switch (name) {
        case 'en':
          return 'English'
          break
        case 'ru':
          return 'Русский'
          break
        // case 'zh':
        //   return '简体中文'
        //   break
        case 'vi':
          return 'Tiếng Việt'
          break
        case 'id':
          return 'Indonesia'
          break
        case 'ja':
          return '日本語'
          break
        case 'ko':
          return '한국어'
          break
        case 'ms':
          return 'Melayu'
          break
        case 'th':
          return 'ไทย'
          break
        // case 'zh-tw':
        //   return '繁體中文'
        //   break
      }
    },
  },
  methods: {
    // 查询分类
    goodclass() {
      http.goodsClass().then((res) => {
        this.typeList = res.data
      })
    },
    tab(item) {
      this.$emit('checkShopType', item)
    },
    toogNav() {
      let nav = document.getElementById('nav')
      if (nav.style.display == 'block') {
        nav.style.display = 'none'
        this.phoneIcon = 'el-icon-more'
      } else {
        nav.style.display = 'block'
        this.phoneIcon = 'el-icon-close'
      }
    },
    // 修改语言然后刷新页面
    langFn(name) {
      changeLang(name, this)
    },
    pageTo(key) {
      let _this = this
      switch (key) {
        case 1:
          _this.$router.push('/')
          break
        case 6:
          _this.$router.push('/login')
          break
        // case 2:
        // 	location.href = 'https://www.interstellarfx.net/zh/trading'
        // 	break;
        // case 3:
        // 	location.href = 'https://www.interstellarfx.net/zh/mt4'
        // 	break;
        // case 4:
        // 	location.href = 'https://www.interstellarfx.net/zh/faq'
        // 	break;
        // case 5:
        // 	location.href = 'https://www.interstellarfx.net/zh/about_us'
        // 	break;
      }
    },
    enter(name) {
      this.$router.push(name)
    },

    jump(url) {
      location.href = url
    },

    login_init() {
      if (localStorage.getItem('token')) {
        this.anniu_login = true
      } else {
        this.anniu_login = false
      }
    },

    top_close_button() {
      this.head_show = false
    },

    // Christmas
    xuehua() {
      const NUMBER_OF_LEAVES = 50

      function init() {
        var container = document.getElementById('leafContainer')
        for (var i = 0; i < NUMBER_OF_LEAVES; i++) {
          container.appendChild(createALeaf())
        }
      }

      function randomInteger(low, high) {
        return low + Math.floor(Math.random() * (high - low))
      }

      function randomFloat(low, high) {
        return low + Math.random() * (high - low)
      }

      function pixelValue(value) {
        return value + 'px'
      }

      function durationValue(value) {
        return value + 's'
      }

      function createALeaf() {
        var leafDiv = document.createElement('div')
        var image = document.createElement('img')
        image.src =
          'https://www.fisg.com/wp-content/uploads/2023/12/snow' +
          randomInteger(1, 5) +
          '.png'
        leafDiv.style.top = '-80px'
        leafDiv.style.left = pixelValue(randomInteger(0, window.innerWidth))
        var spinAnimationName =
          Math.random() < 0.5 ? 'clockwiseSpin' : 'counterclockwiseSpinAndFlip'
        leafDiv.style.webkitAnimationName = 'fade, drop'
        image.style.webkitAnimationName = spinAnimationName
        var fadeAndDropDuration = durationValue(randomFloat(11, 20))
        var spinDuration = durationValue(randomFloat(4, 8))
        leafDiv.style.webkitAnimationDuration =
          fadeAndDropDuration + ', ' + fadeAndDropDuration
        var leafDelay = durationValue(randomFloat(0, 5))
        leafDiv.style.webkitAnimationDelay = leafDelay + ', ' + leafDelay
        image.style.webkitAnimationDuration = spinDuration
        leafDiv.appendChild(image)
        return leafDiv
      }
      window.addEventListener('load', init)
    },
  },
  mounted() {
    this.goodclass()
    const that = this
    let nav = document.getElementById('nav')
    that.w = window.outerWidth
    let list = nav.querySelectorAll('li')
    for (let i = 0; i < list.length; i++) {
      list[i].onclick = function () {
        //if(that.w<=750){
        let dom = this.querySelector('.submenu')
        if (dom.style.display == 'block') {
          dom.style.display = 'none'
        } else {
          // dom.style.display = 'block'
          var menuAll = document.querySelectorAll('.submenu')
          for (i = 0; i < menuAll.length; i++) {
            menuAll[i].style.display = 'none'
          }
          dom.style.display = 'block'
        }
        //}
      }
    }

    // Christmas
    this.$nextTick(() => {
      this.xuehua()
    })

    let langGroup = document.querySelector('.langGroup')
    langGroup.onclick = function () {
      if (that.w <= 750) {
        let dom = this.querySelector('.national')
        if (dom.style.display == 'block') {
          dom.style.display = 'none'
        } else {
          dom.style.display = 'block'
        }
      }
    }

    window.onresize = function () {
      that.w = window.outerWidth
      if (window.outerWidth <= 1160) {
        nav.style.display = 'none'
        that.phoneIcon = 'el-icon-more'
      } else {
        //nav.style.display = 'flex'
        that.phoneIcon = 'el-icon-more'
      }
    }
    this.login_init()

    // // localStorage.getItem('lang') || 'en'
    // let tmp_lang = localStorage.getItem('lang') || "en"
    // //console.log("tmp_lang",tmp_lang)
    // tmp_lang = tmp_lang == "en" ? "" : tmp_lang
    // this.host = tmp_lang=="zh"?"https://www.interstellarfx.net/":
    // tmp_lang==""?"https://www.interstellarsfx.com":"https://www.interstellarsfx.com/"
    // this.lang = tmp_lang
    let langText = localStorage.getItem('lang') || 'en'
    if (langText == 'zh') {
      this.host = 'https://www.fisg.com/'
    } else {
      this.host = 'https://www.fisg.com/'
    }

    let box = document.getElementById('head_fix_box')
    let h = document.querySelector('#app').offsetHeight
    if (h > 2000) {
      jQuery(window).scroll(function () {
        if (jQuery(window).scrollTop() > 100) {
          box.style.position = 'fixed'
          box.style.width = '100%'
          box.style.left = '0'
          box.style.top = '0'
        } else {
          box.style.position = 'relative'
        }
      })
    }
  },
}
</script>

<style scoped="scoped">
.head {
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 999;
  min-height: auto;
}

.head_top {
  background-color: #212529;
  padding: 12px;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  box-sizing: border-box;
}

.head_top_left {
  padding-right: 1%;
}

.head_top_right .el-icon-close:before {
  font-family: element-icons !important;
}

.head_nav_assistant {
  background-color: #f8f9fa;
  height: 58px;
}

.head_nav_assistant ul li {
  line-height: 58px;
  margin-left: 30px;
  font-family: 'Lato-Regular', Sans-serif;
}

.head_nav_assistant ul li img {
  width: 100%;
}

.head_nav_assistant_icon {
  width: 12px;
  margin-top: 3px;
  margin-right: 3px;
}

.head_nav_assistant_font {
  color: #6c757d;
  font-size: 14px;
  line-height: 21px;
}

.head_nav_assistant_font a {
  color: #6c757d;
  text-decoration: none;
}

.head_fix {
  position: relative;
  height: auto;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 30%);
}

.head_fix .container {
  height: 100%;
}

.nav_box {
  height: 90px;
}

.head_fix .nav {
  flex: 1;
  height: 65px;
}

.head_fix .nav li {
  line-height: 90px;
}

.langGroup {
  padding: 0px 0px;
  cursor: pointer;
  font-size: 14px;
  width: auto;
}

.langGroup h4:first-child .nationalicon:first-child {
  display: none !important;
}

.head_fix .nav li h4 {
  padding: 0px 5px;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  font-family: 'Lato-Regular', Sans-serif;
}

.head_fix .nav li .submenu {
  position: absolute;
  left: 0;
  right: 0;
  /* background: #2f3b49; */
  /* padding: 50px 0px 20px 0px; */
  display: none;
  color: #212529;
  height: auto;
  /* 		background: url(@/assets/img/beijing_xiala.png); */
}

.home_schd a {
  color: #000;
  text-decoration: none;
}

.shouye_huan {
  text-decoration: none;
  color: #000;
}

.logo {
  width: 190px;
  height: 50px;
}

.logo_sj {
  display: none;
}

.logo img {
  width: 100%;
}
/* sanjiao
	.head_sj{ 
		width: 1100px;
		margin: 0 auto;
		position: relative;}
	.head_tb{ 
		position: absolute;
		top: 89px;
		left: 70px;
	}
	

	/*下拉菜单*/

.head_sj {
  width: 1100px;
  margin: 0 auto;
  position: relative;
}
.head_tb {
  position: absolute;
  top: 89px;
  left: 70px;
}

.b_left_anniu_a a {
  background: #e00007;
}

.b_left_anniu_b a {
  background: #2d8319;
}

.b_left_anniu h3 {
  height: 70px;
}

.b_left_anniu h3 a {
  padding: 10px 45px;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
}

.head_fix .nav li:hover {
  /* 		background: #2f3b49;
		background: url(@/assets/img/menubg-1.avif);
		color: #fff; */
  color: #198754 !important;
  border-bottom: 2px solid #198754;
}

.submenu .b_left {
  width: 35%;
  margin-top: 150px;
}
.submenu .b_left b {
  display: none;
  font-size: 2.7em;
  line-height: 2;
  font-weight: normal;
}

.b_left_anniu {
  display: none;
}

.submenu .b_right {
  /* 		width: 600px;
		height: 400px; */
}
.submenu .b_right dl {
  flex: 1;
  width: 270px;
}
.submenu .b_right dt {
  font-size: 14px;
  margin-bottom: 15px;
  padding: 20px 20px;
  line-height: 1.2;
  font-weight: bold;
  border-bottom: 2px solid #198754;
  margin-right: 32px;
  background: url(https://www.fisg.com/wp-content/uploads/2023/02/home_icon07.png);
  background-repeat: no-repeat;
  background-position: 0% 51%;
  background-size: 12px;
}
.submenu .b_right dd {
  line-height: 1.7;
}
.submenu .b_right dd a {
  font-size: 14px;
  display: block;
  text-decoration: none;
  color: #333;
  padding: 10px 0px;
  font-weight: bold;
}
.submenu .b_right dt::before {
}

.b_right_icon_left {
  margin-right: 50px;
  display: none;
}

.b_right_icon_left_tb {
  background: url('@/assets/img/menuicon1.png');
  width: 17px;
  height: 17px;
  background-size: 100% 100%;
  /* margin-top: 36px; */
  margin-right: 5px;
}

.b_right_icon_right {
  display: none;
}

.b_right_icon_right_tb {
  background: url('@/assets/img/menuicon2.png');
  width: 17px;
  height: 17px;
  background-size: 100% 100%;
  /* margin-top: 36px; */
  margin-right: 5px;
}

.b_right_icon_left span img {
  width: 100%;
}

.b_right_icon_left a {
  color: #fff;
  text-decoration: none;
}

.b_right_box {
  width: 70%;
  float: left;
  background: #fff;
  padding: 8px 32px 8px 32px !important;
  border-radius: 0px 0px 15px 15px;
  display: inline-block;
}

.b_right_icon_right span img {
  width: 20%;
}

.b_right_icon_right a {
  color: #fff;
  text-decoration: none;
}
/*下拉菜单End*/

.anniu_guanbi {
  right: 20px;
  position: absolute;
  top: -10px;
  font-size: 24px;
  display: none;
}
.nationalicon {
  width: 16px;
  height: 11px;
  background-color: #000;
  margin-right: 5px;
  display: none;
  /* mk */
}

.nationalicon.zh {
  background: url('../assets/img/national/zh.png');
  background-size: 100% 100%;
}

.nationalicon.en {
  background: url('../assets/img/national/en.png');
  background-size: 100% 100%;
}

.nationalicon.ru {
  background: url('../assets/img/national/ru.png');
  background-size: 100% 100%;
}

.nationalicon.vi {
  background: url('../assets/img/national/vi.png');
  background-size: 100% 100%;
}

.nationalicon.id {
  background: url('../assets/img/national/id.png');
  background-size: 100% 100%;
}

.nationalicon.ja {
  background: url('../assets/img/national/ja.png');
  background-size: 100% 100%;
}

.nationalicon.ko {
  background: url('../assets/img/national/ko.png');
  background-size: 100% 100%;
}

.nationalicon.th {
  background: url('../assets/img/national/th.png');
  background-size: 100% 100%;
}

.nationalicon.ms {
  background: url('../assets/img/national/m.png');
  background-size: 100% 100%;
}

.nationalicon.zh-tw {
  background: url('../assets/img/national/tw.png');
  background-size: 100% 100%;
}

.el-icon-caret-bottom:before {
  font-family: element-icons !important;
}

.national {
  position: absolute;
  left: -30px;
  top: 100%;
  z-index: 99;
  width: 100%;
  line-height: 1.5;
  background-color: #fff;
  display: none;
  width: 150px;
}

.national .item {
  width: 100%;
  height: 35px;
  padding: 0px 15px;
  cursor: pointer;
}

.national .item:hover {
  background-color: #eee;
}

.head_fix .nav .langGroup:hover .national {
  display: block;
}

.langGroup:hover .national {
  display: block;
}

.login_button {
  height: 45px;
  background-color: #198754;
  color: #fff;
  cursor: pointer;
  margin-top: 22px;
  margin-bottom: 25px;
  border-radius: 50px;
}

.login_button svg {
  margin-right: 5px;
}

.login_button button {
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
  /* width: 130px; */
  /* height: 40px; */
  color: #fff;
  cursor: pointer;
  /* padding: 15px 15px 15px 10px; */
  padding: 0 15px;
  font-size: 15px;
  font-family: 'Lato-Regular', Sans-serif;
}

.phoneMenu {
  display: none;
}

.nav_a {
  position: absolute;
  background: #e00008;
  width: 100%;
  left: 0;
  height: 100%;
  top: 90px;
}

.nav_a_box {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}

.nav_a_box_left {
  float: left;
}

.nav_a_box_right {
  float: right;
}

/* 	.head_fix .nav li:hover .div{
		display: black;
	} */

@media screen and (min-width: 768px) and (max-width: 1160px) {
  .logo {
    width: 100%;
    height: 58px;
  }

  .logo_pc {
    margin-top: 5px;
    margin-left: 5px;
    display: none;
  }

  .logo_sj {
    display: block;
  }

  .logo img {
    width: 60px;
    margin: 12px;
  }

  .logo_sj_box {
    height: 58px;
  }

  .logo_sj_box_left {
    width: 50%;
  }

  .head {
    height: auto;
    min-height: auto !important;
  }

  .head_fix {
    height: auto;
  }

  .header {
    height: auto;
  }

  .nav_box {
    height: auto;
  }

  .head_nav_assistant {
    display: none;
  }

  .phoneMenu {
    width: 100%;
    height: 40px;
    /* 			position: absolute;
					right: 3%; */
    display: block;
    /* 			top: 50%;
					transform: translateY(-50%); */
    font-size: 24px;
    font-weight: 500;
    background: #212529;
    color: #fff;
  }
  .phoneMenu i {
    float: right;
    margin-top: 10px;
    margin-right: 12px;
  }

  .phoneMenu svg {
    float: right;
    margin-top: 5px;
    margin-right: 12px;
  }

  .nav {
    margin-left: 0 !important;
    height: calc(100vh - 65px) !important;
    display: none;
    position: absolute !important;
    background-color: #2f3b49;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: auto;
  }

  .nav li {
    width: 100%;
    text-align: center;
    text-align: left;
    line-height: 50px !important;
    height: auto !important;
  }
  .head_fix .nav li:visited .submenu {
    display: none;
  }
  .nav li h4 {
    color: #fff !important;
    font-weight: 400;
  }

  .national {
    background-color: #1d242d;
    position: relative !important;
    top: 0 !important;
    width: 100%;
    left: 0;
  }

  .national .item {
    color: #fff;
    justify-content: center;
  }

  .national .item:hover {
    color: #000;
  }

  .login_button {
    width: 90%;
    margin-left: 5%;
  }

  .head_sj {
    display: none;
  }

  .langGroup {
    line-height: 90px;
    padding: 0px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    width: auto;
  }

  .login_button button {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
  }

  .login_button_sj {
    display: block !important;
    width: auto;
    line-height: 45px;
    margin: 12px;
  }

  /* 下拉菜单 */
  .submenu .b_left {
    display: none;
  }

  .submenu_box_ner {
    display: block;
    width: 92% !important;
  }

  .submenu .b_right {
    width: 100%;
    height: auto;
    display: block;
  }

  .b_right_icon {
    display: none;
  }
  .head_fix .nav li .submenu {
    position: relative;
    height: auto;
    padding-top: 0;
    padding-bottom: 80px;
    background-size: 100% 100%;
  }
  .line_none {
    display: none;
  }

  .login_button {
    display: none;
  }

  .b_right_box {
    width: auto;
    float: none;
    background: none;
    padding: 8px 32px 8px 32px !important;
    border-radius: 0px 0px 15px 15px;
    display: inline-block;
  }

  .submenu .b_right dt[data-v-4a49499c] {
    color: #fff;
  }

  .submenu .b_right dd a {
    color: #ffffff;
    font-family: 'Lato-Regular', Sans-serif;
  }

  #langGrou_sj {
    display: block !important;
    padding: 0px 10px;
    line-height: 45px;
  }
  .integral_sj {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 100%;
    height: 58px;
  }

  .logo_pc {
    margin-top: 5px;
    margin-left: 5px;
    display: none;
  }

  .logo_sj {
    display: block;
  }

  .logo img {
    width: 60px;
    margin: 12px;
  }

  .logo_sj_box {
    height: 58px;
  }

  .logo_sj_box_left {
    width: 50%;
  }

  .head {
    height: auto;
    min-height: auto !important;
  }

  .head_fix {
    height: auto;
  }

  .header {
    height: auto;
  }

  .nav_box {
    height: auto;
  }

  .head_nav_assistant {
    display: none;
  }

  .phoneMenu {
    width: 100%;
    height: 40px;
    /* 			position: absolute;
			right: 3%; */
    display: block;
    /* 			top: 50%;
			transform: translateY(-50%); */
    font-size: 24px;
    font-weight: 500;
    background: #212529;
    color: #fff;
  }
  .phoneMenu i {
    float: right;
    margin-top: 10px;
    margin-right: 12px;
  }

  .phoneMenu svg {
    float: right;
    margin-top: 5px;
    margin-right: 12px;
  }

  .nav {
    margin-left: 0 !important;
    height: calc(100vh - 200px) !important;
    display: none;
    position: absolute !important;
    background-color: #2f3b49;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: auto;
    bottom: 0;
  }

  .nav li {
    width: 100%;
    text-align: center;
    text-align: left;
    line-height: 50px !important;
    height: auto !important;
  }
  .head_fix .nav li:visited .submenu {
    display: none;
  }
  .nav li h4 {
    color: #fff !important;
    font-weight: 400;
  }

  .national {
    background-color: #1d242d;
    position: relative !important;
    top: 0 !important;
    width: 100%;
    left: 0;
  }

  .national .item {
    color: #fff;
    justify-content: center;
  }

  .national .item:hover {
    color: #000;
  }

  .login_button {
    width: 90%;
    margin-left: 5%;
    display: none;
  }

  .head_sj {
    display: none;
  }

  .langGroup {
    line-height: 90px;
    padding: 0px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    width: auto;
  }

  .login_button button {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
  }

  .login_button_sj {
    display: block !important;
    width: auto;
    line-height: 45px;
    margin: 12px;
  }

  /* 下拉菜单 */
  .submenu .b_left {
    display: none;
  }

  .submenu_box_ner {
    display: block;
    width: 92% !important;
  }

  .submenu .b_right {
    width: 100%;
    height: auto;
    display: block;
  }

  .b_right_icon {
    display: none;
  }
  .head_fix .nav li .submenu {
    position: relative;
    height: auto;
    padding-top: 0;
  }
  .line_none {
    display: none;
  }
  .b_right_box {
    width: auto;
    float: none;
    background: none;
    padding: 8px 32px 8px 32px !important;
    border-radius: 0px 0px 15px 15px;
    display: inline-block;
  }

  .submenu .b_right dt {
    color: #fff;
    font-family: 'Lato-Regular', Sans-serif;
  }

  .submenu .b_right dd a {
    color: #ffffff;
  }

  #langGrou_sj {
    display: block !important;
    padding: 0px 10px;
    line-height: 45px;
  }
  .integral_sj {
    display: block !important;
  }
}
</style>
<style>
/* remove */

#leafContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

#leafContainer > div {
  position: absolute;
  max-width: 100px;
  max-height: 100px;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-direction: normal, normal;
  -webkit-animation-timing-function: linear, ease-in;
}

#leafContainer > div > img {
  width: 100%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-transform-origin: 50% -100%;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes drop {
  0% {
    -webkit-transform: translate(0px, -50px);
  }

  100% {
    -webkit-transform: translate(0px, 2180px);
  }
}

@-webkit-keyframes clockwiseSpin {
  0% {
    -webkit-transform: rotate(-50deg);
  }

  100% {
    -webkit-transform: rotate(50deg);
  }
}

@-webkit-keyframes counterclockwiseSpinAndFlip {
  0% {
    -webkit-transform: scale(-1, 1) rotate(50deg);
  }

  100% {
    -webkit-transform: scale(-1, 1) rotate(-50deg);
  }
}

#leafContainer,
#leafContainer * {
  pointer-events: none !important;
}
</style>
